import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { login } from "../../redux/authSlice";
import InputWithIcon from "../../components/InputWithIcon/InputWithIcon";
import "./LogIn.css";

const Login = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { loading, error, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  useEffect(() => {
    if (user) {
      if (onLoginSuccess) {
        onLoginSuccess();
      } else {
        navigate("/Quests");
      }
    }
  }, [user, navigate, onLoginSuccess]);

  return (
    <div className="container-signin">
      <div className="container-login">
        <img alt="logo Ashara" src="/img/User/logo-ashara.png" className="logo" />
        <h2>Sign IN</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email:</label>
            <InputWithIcon
              type="email"
              value={email}
              autocomplete="username"
              onChange={(e) => setEmail(e.target.value)}
              img="/img/User/IconUser.png"
            />
          </div>
          <div>
            <label>Password:</label>
            <InputWithIcon
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              img="/img/User/IconPassword.png"
              autocomplete="current-password"
            />
            <p className="txt-password">
              Forgot your password?  <Link to="/ResetPassword">Click Here.</Link>
            </p>
          </div>
          <button className="btn-form" type="submit" disabled={loading}>
            {loading ? "Logging in..." : "Log In"}
          </button>
          {error && <p className="txt-form">{error.msg}</p>}
        </form>
        <p className="txt-login">
          Not a member yet? <Link to="/SignUp">Get started here.</Link>
        </p>
      </div>
      <img className="bg-login" alt="logo Ashara" src="/img/User/logoBig.png" />
    </div>
  );
};

export default Login;