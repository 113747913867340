import React, { useEffect } from "react";
import "./Inventory.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import CoinItem from "./Coin/Coin";
import { fetchInventory } from "../../../redux/inventorySlice";
import Items from "./Items/Items";
import Loading from "../../../components/Loading/Loading";

export default function Inventory() {
    const dispatch = useDispatch();
    const { statusInventory } = useSelector((state) => state.inventory);
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (user !== null & statusInventory === "idle") {
            dispatch(fetchInventory(user._id));
        }
    }, [user, statusInventory, dispatch]);

    useEffect(() => {
        if (!user) {
            navigate("/SignIn");
        }
    }, [user, navigate]);
    return (
        <section className="container-inventory">
            {statusInventory === "loading" && <Loading />}
            {statusInventory === "succeeded" && (
                <section className="container-inventorydb">
                    <CoinItem />
                    <Items />
                </section>
            )}
        </section>

    );
};


