import React, { useEffect, useState } from "react";
import "./AsharaPasses.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchPasses } from '../../../redux/nftSlice';
import Loading from "../../../components/Loading/Loading";

export default function AsharaPasses() {
    const { user } = useSelector((state) => state.auth);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const { status, error, asharaPass } = useSelector((state) => state.nfts);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user !== null & status === "idle") {
            dispatch(fetchPasses(user._id));
        }
    }, [user, status, dispatch]);

    const countUserHasPass = (asharaPass) => {
        return asharaPass.reduce((count, pass) => {
            if (pass.userHasPass) {
                return count + 1;
            }
            return count;
        }, 0);
    };

    if (status === "loading") {
        return <Loading />
    }

    if (status === "failed") {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="container-nft2">
            <p className="txt-nfts">{countUserHasPass(asharaPass)} out of {asharaPass.length} Ashara Passes</p>
            <section className="container-passes">
                {asharaPass.map((pass, index) => (
                    <div key={`${index}AP`}>
                        <div className="container-nft3">
                            <p>{pass.name}</p>
                            <div className="container-pass"
                                onMouseEnter={() => setHoveredIndex(`${index}AP`)}
                                onMouseLeave={() => setHoveredIndex(null)}
                            >
                                <img className="img-pass" src={pass.display_image_url} alt={pass.name} />
                                {hoveredIndex === `${index}AP` && (
                                    <div className="hover-txt"><p>{pass.description}</p></div>
                                )}
                            </div>
                        </div>
                        {!pass.userHasPass &&
                            <a className="button2 btn-pass"
                                href={pass.opensea_url}
                                target={"_blank"}
                                rel="noreferrer">Buy Now!
                            </a>
                        }

                    </div>
                ))
                }
            </section>
        </div>
    );
}