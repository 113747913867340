import React from 'react';
import './PopUp.css';

const PopUp = ({ onClose, preregistered, noClose, children }) => {
    return (
        <div className={`popup-overlay ${preregistered ? "popup-preregistered" : ""}`}>
            {(noClose === undefined || noClose === false) && (
                <button onClick={onClose} className="close-button">
                    x
                </button>
            )}
            <img
                className="popup-logo"
                src="/img/5.PopUp/logoPopUp.webp"
                alt="Logo Ashara Studios"
            />
            <div className="popup-body">
                {children}
            </div>
        </div>
    );
};

export default PopUp;