import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import Button from "../../components/Buttton/Button";
import Inventory from "./Inventory/Inventory";
import Medals from "./Medals/Medals";
import AsharaPasses from "./Passes/AsharaPasses";

export default function Dashboard() {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <article className={`container-dashboard   ${location.pathname.includes("/Dashboard/Inventory") && 'inventory'} ${(location.pathname.includes("/Dashboard/Medals") || location.pathname.includes("/Dashboard/SeasonPass")) && 'nft'}`}>
            <section className="season-menu">
                <Button
                    variant='secondary'
                    size="btn-sm"
                    onClick={() => navigate("/Dashboard/Inventory")}
                    isActive={location.pathname.includes("/Dashboard/Inventory")}
                >
                    Inventory
                </Button>
                <Button
                    variant='secondary'
                    size="btn-sm"
                    onClick={() => navigate("/Dashboard/Medals")}
                    isActive={location.pathname.includes("/Dashboard/Medals")}
                >
                    Medals
                </Button>
                <Button
                    variant='secondary'
                    size="btn-sm"
                    onClick={() => navigate("/Dashboard/SeasonPass")}
                    isActive={location.pathname.includes("/Dashboard/SeasonPass")}
                >
                    Season Pass
                </Button>
            </section>
            <hr className="hr-dashboard" />

            {location.pathname.includes("/Dashboard/Inventory") && <Inventory />}
            <div className="conteiner-nft">
                {location.pathname.includes("/Dashboard/Medals") && <Medals />}
                {location.pathname.includes("/Dashboard/SeasonPass") && <AsharaPasses />}
            </div>
        </article>
    );
}
