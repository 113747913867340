import React, { useEffect, useState, useMemo } from "react";
import "./Quests.css";
import Quest from "./Quest/Quest";
import { useSelector, useDispatch } from "react-redux";
import { fetchGamerQuests } from "../../redux/questsSlice";
import { checkSessionExpiration } from '../../redux/authSlice';

import Loading from "../../components/Loading/Loading";

export default function Quests() {
  const dispatch = useDispatch();
  const stateQuest = ["All", "To Do", "In Review", "Not Approved", "Completed"];
  const games = ["All", "Arkane Bestiary", "ToM: Genbu's Travel", "ToM: Oldbeel Tavern", "ToM: Monster Chef", "ToM: Colosseum", "ToM: Gaia's requiem","Ex: Cthulhu's Nightmare", "Kai's Nightmare", "Tower of Myths", "Exorcist Reborn"];
  const types = ["All", "Score", "Medal", "Variable Score", "Item"];
  const questTypeOptions = ["All", "Daily Quest", "Normal Quest"];
  const currentDate = new Date().toISOString();

  const ArryQuest = useSelector((state) => state.quests.items);
  const error = useSelector((state) => state.quests.error);
  const questStatus = useSelector((state) => state.quests.status);
  const { user } = useSelector((state) => state.auth);

  const activeQuests = useMemo(() => {
    const normalQuests = ArryQuest.filter(quest => !quest.daily);
    const activeDailyQuests = ArryQuest.filter(quest => quest.daily && (currentDate < quest.endDate && currentDate > quest.startDate));
    return [...activeDailyQuests, ...normalQuests];
  }, [ArryQuest, currentDate]);

  const [selectedState, setSelectedState] = useState("All");
  const [selectedGame, setSelectedGame] = useState("All");
  const [selectedType, setselectedType] = useState("All");
  const [selectedQuestType, setSelectedQuestType] = useState("All");

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const handleGameChange = (e) => {
    setSelectedGame(e.target.value);
  };

  const handleTypeChange = (e) => {
    setselectedType(e.target.value);
  };

  const handleQuestTypeChange = (e) => {
    setSelectedQuestType(e.target.value);
  };

  const filteredQuests = useMemo(() => {
    let filteredQuest = activeQuests;

    if (selectedState !== "All") {
      filteredQuest = filteredQuest.filter(
        (quest) => quest.gamerCreated.questStatus.toUpperCase() === selectedState.toUpperCase()
      );
    }

    if (selectedGame !== "All") {
      filteredQuest = filteredQuest.filter(
        (quest) => quest.game.name.toUpperCase() === selectedGame.toUpperCase()
      );
    }

    if (selectedType !== "All") {
      filteredQuest = filteredQuest.filter(
        (quest) => selectedType === "Score" ? quest.reward.Points > 0 :
          selectedType === "Medal" ? quest.reward.medalObj?.name :
            selectedType === "Variable Score" ? quest.reward.formula :
              selectedType === "Item" ? quest.reward.item?.name : false
      );
    }

    if (selectedQuestType !== "All") {
      filteredQuest = filteredQuest.filter(
        (quest) => selectedQuestType === "Daily Quest" ? quest.daily :
          selectedQuestType === "Normal Quest" ? !quest.daily : true
      );
    }

    return filteredQuest;
  }, [selectedState, selectedGame, selectedType, selectedQuestType, activeQuests]);

  useEffect(() => {
    if (questStatus === "idle") {
      dispatch(fetchGamerQuests(0));
    }
  }, [questStatus, dispatch]);

  useEffect(() => {
    if (checkSessionExpiration(dispatch) === false) {
      dispatch(fetchGamerQuests(0));
      setSelectedState("All");
      setSelectedGame("All");
      setselectedType("All");
      setSelectedQuestType("All");
    } else {
      if (user) {
        dispatch(fetchGamerQuests(user._id));
      } else {
        dispatch(fetchGamerQuests(0));
        setSelectedState("All");
        setSelectedGame("All");
        setselectedType("All");
        setSelectedQuestType("All");
      }
    }

  }, [user, dispatch]);

  return (
    <article className="container-quests">
      <div className="container-quests-filter">
        <h1 className="title-quest">
          Quests <hr />
        </h1>
        {user && (
          <>
            <p className="txt-filter">Filter by:</p>
            <label htmlFor="game-mision">Game
              <select
                id="game-mision"
                name="game-mision"
                className="select-mision"
                value={selectedGame}
                onChange={handleGameChange}
              >
                {games.map((game, index) => (
                  <option value={game} key={"game" + index}>
                    {game}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="quest-type-mision">Quest Type
              <select
                id="quest-type-mision"
                name="quest-type-mision"
                value={selectedQuestType}
                onChange={handleQuestTypeChange}
              >
                {questTypeOptions.map((questType, index) => (
                  <option value={questType} key={"questType" + index}>
                    {questType}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="type-mision">Reward Type
              <select
                id="type-mision"
                name="type-mision"
                value={selectedType}
                onChange={handleTypeChange}
              >
                {types.map((type, index) => (
                  <option value={type} key={"type" + index}>
                    {type}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="state-mision">Status Quest
              <select
                id="state-mision"
                name="state-mision"
                value={selectedState}
                onChange={handleStateChange}
              >
                {stateQuest.map((state, index) => (
                  <option value={state} key={"state" + index}>
                    {state}
                  </option>
                ))}
              </select>
            </label>
          </>
        )}
      </div>
      {questStatus === "loading" && <Loading />}
      {questStatus === "failed" && <p>{error}</p>}
      {questStatus === "succeeded" && (
        <div className="container-listQuest">
          {filteredQuests.map((quest) => <Quest quest={quest} key={quest._id} />)}
          {filteredQuests.length === 0 &&
            <h1 className="txt-quest">You do not have Quests associated with the applied filter.</h1>
          }
        </div>
      )}
    </article>
  );
}
