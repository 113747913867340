import React from "react";
import "./RedesSociales.css";

export default function RedesSociales() {
  return (
    <div className="RedesSociales">
      <a
        href={"https://twitter.com/asharastudios"}
        target={"_blank"}
        rel="noreferrer"
         aria-label="Síguenos en Twitter"
      >
        <img
          className="RedesSociales-imgLogo"
          src="/img/4.SocialNetwork/Twitter.png"
          alt="Twitter"
        />
      </a>
      <a
        href={"https://www.instagram.com/asharastudios/"}
        target={"_blank"}
        rel="noreferrer"
      >
        <img
          className="RedesSociales-imgLogo"
          src="/img/4.SocialNetwork/Instagram.png"
          alt="Instagram"
        />
      </a>
      <a
        href={"https://www.linkedin.com/company/asharastudios/"}
        target={"_blank"}
        rel="noreferrer"
      >
        <img
          className="RedesSociales-imgLogo"
          src="/img/4.SocialNetwork/LinkedIn.png"
          alt="Instagram"
        />
      </a>
      <a
        href={"https://www.youtube.com/channel/UCKtLoZ8R_ieK6N4o2L9i44A"}
        target={"_blank"}
        rel="noreferrer"
      >
        <img
          className="RedesSociales-imgLogo"
          src="/img/4.SocialNetwork/Youtube.png"
          alt="Instagram"
        />
      </a>
      <a
        href={"https://www.facebook.com/AsharaStudios/"}
        target={"_blank"}
        rel="noreferrer"
      >
        <img
          className="RedesSociales-imgLogo"
          src="/img/4.SocialNetwork/Facebook.png"
          alt="Facebook"
        />
      </a>
    </div>
  );
}
