
export const ASHARA_SEASON = "AsharaSeasonIII";

export const NUMBER_PLAYERS = [
    { sand: 400, players: 10 },
    { sand: 400, players: 20 },
    { sand: 400, players: 30 },
    { sand: 400, players: 40 },
    { sand: 400, players: 50 },
    { sand: 400, players: 60 },
    { sand: 400, players: 70 },
    { sand: 400, players: 80 },
    { sand: 400, players: 90 },
    { sand: 400, players: 100 },
];

export const NUMBER_SANDS = [
    { sand: '25%', players: 1 },
    { sand: '25%', players: 2 },
    { sand: '25%', players: 3 },
    { sand: '25%', players: 4 }
];

export const BASE_URL = "https://walrus-app-8wc7g.ondigitalocean.app";

export const ASHARA_SEASON_STYLE = {
    "background": "https://ashara-web.s3.us-east-2.amazonaws.com/Season/AsharaSeason3/bakcground.webp",
    "image": "https://ashara-web.s3.us-east-2.amazonaws.com/Season/AsharaSeason3/AS3+2.webp"
}