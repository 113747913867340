import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchLeaderboard } from '../../../redux/leaderboardSlice';
import "./Leaderboard.css";
import Loading from "../../../components/Loading/Loading";
import utils from "../../../utils/utils";

function Leaderboard() {
    const dispatch = useDispatch();
    const { status, leaderboard } = useSelector((state) => state.leaderboard);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (status === "idle") {
            dispatch(fetchLeaderboard());
        }
    }, [status, dispatch]);

    return (
        status === "loading" ? (
            <Loading />
        ) : (
            <div className="container-leaderboard">
                <div className="continer-row-title">
                    <h2>Rank</h2>
                    <h2>Ashara Pass</h2>
                    <h2>Nickname</h2>
                    <h2>Inital Score</h2>
                    <h2>Medal Count</h2>
                    <h2>Total score multiplier</h2>
                    <h2>Will you win some sand?</h2>
                </div>
                <hr className="continer-row-title " />
                <section className="container-leaderboard-table">
                    {leaderboard.map((gamer, index) => (
                        <div key={"gamer" + index}>
                            <div className={`continer-row${user && user._id === gamer._id ? "-user" : ""}`}>
                                {(user && user._id === gamer._id) &&
                                    <img
                                        className="user-arrow"
                                        src="/img/Leaderboard/userArrow.png"
                                        alt="user-arrow"
                                    />
                                }
                                {index === 0 &&
                                    <img
                                        className="img-top top1"
                                        src="/img/Leaderboard/Rank_Gold.png"
                                        alt="Rank_Gold"
                                    />
                                }
                                {index === 1 &&
                                    <img
                                        className="img-top top2"
                                        src="/img/Leaderboard/Rank_Silver.png"
                                        alt="Rank_Gold"
                                    />
                                }
                                {index === 2 &&
                                    <img
                                        className="img-top"
                                        src="/img/Leaderboard/Rank_Bronze.png"
                                        alt="Rank_Gold"
                                    />
                                }

                                <p className={`number${index < 3 ? '-top' : ''}`}> {index === 0 ? '1st' : index === 1 ? '2nd' : index === 2 ? '3th' : index + 1}</p>
                                {
                                    gamer.hasAsharaPass ?
                                        <img className="" src="/img/Leaderboard/StarAsharaPass.png" alt="Start" /> :
                                        <img className="" src="/img/Leaderboard/StarAsharaPassFalse.png" alt="StartFalse" />
                                }
                                < p className={`txt-table${user && user._id === gamer._id ? "-user" : ""}`}> {gamer.nickname}</p>
                                <p className="txt-table">{utils.roundToThreeDecimals(gamer.totalScore)}</p>
                                <p className="txt-table">{gamer.numberOfMedals}</p>
                                <p className="txt-table">{gamer.totalScoreMultiplier}</p>
                                <p className="txt-table">{gamer.receivesSand ? "Yes 😁" : "Not yet 😟"}</p>
                            </div>
                            {index === 0 ? <hr className="one" /> : index === 1 ? <hr className="two" /> : index === 2 ? <hr className="tree" /> : <hr />}
                        </div>
                    ))}
                </section>
            </div >
        )
    );
}

export default Leaderboard;
