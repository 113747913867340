import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from '../variables';

export const fetchLeaderboard = createAsyncThunk(
    "leaderboard/fetchLeaderboard",
    async () => {
        const response = await axios.get(`${BASE_URL}/leaderboard/S3`);
        return response.data;
    }
);

const leaderboardSlice = createSlice({
    name: "leaderboard",
    initialState: {
        leaderboard: [],
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeaderboard.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchLeaderboard.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.leaderboard = action.payload;
            })
            .addCase(fetchLeaderboard.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
    },
});

export default leaderboardSlice.reducer;