import React from 'react';
import './Button.css';

const Button = ({ children, onClick, variant = 'primary', size = 'btn-md', isDisabled = false, isActive }) => {
    return (
        <button
            className={`responsive-button ${variant} ${size} ${isActive ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
            onClick={onClick}
            disabled={isDisabled}
        >
            {children}
        </button>
    );
};

export default Button;
