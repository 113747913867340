import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Preregister.css";
import {
    createPreregister,
    checkPreregister,
    selectIsPreregistered,
    selectPreregisterStatus,
    selectPreregisterError,
} from '../../../redux/preregisterSlice';
import Loading from '../../../components/Loading/Loading';
import InputWithIcon from '../../../components/InputWithIcon/InputWithIcon';
import PopUp from '../../../components/PopUp/PopUp';
import Button from "../../../components/Buttton/Button";
import { login } from "../../../redux/authSlice";

export default function Preregister({ seasonId }) {
    const dispatch = useDispatch();
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { loading, error, user } = useSelector((state) => state.auth);

    const openPopUp = () => setIsPopUpOpen(true);
    const closePopUp = () => setIsPopUpOpen(false);
    const isPreregistered = useSelector(selectIsPreregistered);
    const status = useSelector(selectPreregisterStatus);
    const errorPreregister = useSelector(selectPreregisterError);

    useEffect(() => {
        if (user?._id && seasonId) {
            dispatch(checkPreregister({ userId: user._id, seasonId })).then((result) => {
                if (result.meta.requestStatus === 'fulfilled' && result.payload.isPreregistered) {
                    setShowConfirmationPopup(false);
                }
            });
        }
    }, [user, seasonId, dispatch]);

    const handlePreregister = useCallback(async () => {
        if (user?._id && seasonId) {
            const checkResult = await dispatch(checkPreregister({ userId: user._id, seasonId }));

            if (checkResult.meta.requestStatus === 'fulfilled' && !checkResult.payload.isPreregistered) {
                const result = await dispatch(createPreregister({ userId: user._id, seasonId }));
                if (result.meta.requestStatus === 'fulfilled') {
                    closePopUp();
                    setShowConfirmationPopup(true);
                }
            } else {
                alert('Ya estás preregistrado para esta temporada.');
                closePopUp();
            }
        } else {
            alert('User ID or Season ID is missing.');
        }
    }, [user, seasonId, dispatch]);

    useEffect(() => {
        if (user?._id && seasonId && isPopUpOpen) {
            handlePreregister();
        }
    }, [user, seasonId, isPopUpOpen, handlePreregister]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await dispatch(login({ email, password }));
    };

    return (
        <section className="container-preregister">
            <p className="preregister__number">10k</p>
            <p className="preresgister__sub">Ashara coins!</p>
            <p className="preregister__desc">
                Earn <span>10k</span> Ashara coins when you pre-register before February 26!
            </p>
            <p className="preregister__desc2">Don’t miss out on this exclusive reward!</p>
            {user ?
                isPreregistered ?
                    <p className="preregister__desc2" style={{ margin: `20px auto`, color: `var(--color-text-important)` }}>You are already pre-registered!</p>
                    :
                    <Button size='btn-lg'  onClick={handlePreregister}>
                        Pre-registration!
                    </Button>
                :
                <Button size='btn-lg' onClick={openPopUp}>
                    Pre-registration!
                </Button>
            }
            {isPopUpOpen && (
                <PopUp onClose={closePopUp} preregistered="true">
                    <h2>PRE REGISTRATION</h2>
                    <hr />
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Email:</label>
                            <InputWithIcon
                                type="email"
                                value={email}
                                autocomplete="username"
                                onChange={(e) => setEmail(e.target.value)}
                                img="/img/User/IconUser.png"
                            />
                        </div>
                        <div>
                            <label>Password:</label>
                            <InputWithIcon
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                img="/img/User/IconPassword.png"
                                autocomplete="current-password"
                            />
                            <p className="txt-password">
                                Forgot your password?  <Link to="/ResetPassword">Click Here.</Link>
                            </p>
                        </div>
                        <Button type="submit" isDisabled={loading}>
                            {loading ? "Logging in..." : "Log In"}
                        </Button>
                        {error && <p className="txt-form">{error.msg}</p>}
                    </form>
                    <p className="txt-login">
                        Not a member yet? <Link to="/SignUp">Get started here.</Link>
                    </p>
                </PopUp>
            )}

            {showConfirmationPopup &&
                <PopUp onClose={() => setShowConfirmationPopup(false)} preregistered="true">
                    <h2>PRE REGISTRATION</h2>
                    <hr />
                    <p className="sub-tittle">YOU DID IT !</p>
                    <p className="sub-tittle2"> Check your account </p>
                    <p className="sub-tittle10k"> 10K </p>
                    <p className="txt-PopUp">Ashara Coins!</p>
                </PopUp>
            }
            {showConfirmationPopup && !isPreregistered && (
                <PopUp onClose={() => setShowConfirmationPopup(false)} preregistered="true">
                    <h2>PRE REGISTRATION</h2>
                    <hr />
                    <p className="sub-tittle">YOU DID IT !</p>
                    <p className="sub-tittle2"> Check your account </p>
                    <p className="sub-tittle10k"> 10K </p>
                    <p className="txt-PopUp">Ashara Coins!</p>
                </PopUp>
            )}
            {status === 'loading' && <Loading />}
            {status === 'failed' && <p>Error: {errorPreregister}</p>}
        </section>
    );
}