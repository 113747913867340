import React from "react";
import "./ListGames.css";

const ListGames = ({ game }) => {

  return (
      <article
        className="container-listGames"
      >
        <img src={game.smallLogo} alt={game.name} loading="lazy" />
          <p className="container-listGames__tittle">{game.name}</p>
      </article>

  );
};

export default ListGames;