import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  token: localStorage.getItem("token") || null,
  loading: false,
  error: null,
};

const API_URL = "https://walrus-app-8wc7g.ondigitalocean.app";
const EXPIRATION_TIME = 10 * 24 * 60 * 60 * 1000;

const setSessionData = (user, token) => {
  const sessionData = {
    user,
    token,
    timestamp: new Date().getTime(),
  };
  localStorage.setItem("user", JSON.stringify(sessionData.user));
  localStorage.setItem("token", sessionData.token);
  localStorage.setItem("sessionData", JSON.stringify(sessionData));
};

export const checkSessionExpiration = (dispatch) => {
  const sessionData = JSON.parse(localStorage.getItem("sessionData"));
  if (!sessionData) return false;

  const currentTime = new Date().getTime();
  const timeElapsed = currentTime - sessionData.timestamp;
  const timeRemaining = EXPIRATION_TIME - timeElapsed;

  if (timeElapsed >= EXPIRATION_TIME) {
    dispatch(logout());
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("sessionData");
    alert("Your session has expired. Please log in again.");
    return false;
  } else {
    setTimeout(() => {
      dispatch(logout());
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("sessionData");
      alert("Your session has expired. Please log in again.");
    }, timeRemaining);
  }
  return true;
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/user/signin`, {
        email,
        password,
      });
      const loginTime = new Date().getTime();
      localStorage.setItem('loginTime', loginTime);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signup = createAsyncThunk(
  "auth/signup",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/user/signup`, userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (user, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${API_URL}/user/update/${user.id}`, user.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.user = null;
      state.token = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("sessionData");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        setSessionData(action.payload.user, action.payload.token);
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(signup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        setSessionData(action.payload.user, action.payload.token);
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        setSessionData(action.payload.user, state.token);
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
