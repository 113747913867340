import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ASHARA_SEASON, BASE_URL } from '../variables';

export const fetchSeason = createAsyncThunk('season/fetchSeason', async () => {
    try {
        const response = await fetch(`${BASE_URL}/season/name/${ASHARA_SEASON}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Failed to fetch season: ${error.message}`);
    }
});

const seasonSlice = createSlice({
    name: 'season',
    initialState: {
        season: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSeason.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSeason.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.season = action.payload;
            })
            .addCase(fetchSeason.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch season';
            });
    },
});

export const selectSeason = (state) => state.season.season;
export const selectSeasonStatus = (state) => state.season.status;
export const selectSeasonError = (state) => state.season.error;

export default seasonSlice.reducer;