import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import imageCompression from 'browser-image-compression';
import axios from "axios";

import { BASE_URL } from '../variables';

export const fetchGamerQuests = createAsyncThunk(
  "quests/fetchGamerQuests",
  async (gamerId) => {
    const response = await axios.get(`${BASE_URL}/gamer/${gamerId}/quests`);
    return response.data;
  }
);

export const createGamerQuest = createAsyncThunk(
  "quests/createGamerQuest",
  async (
    { file, questId, gamerId },
    { rejectWithValue }
  ) => {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      });

      const formData = new FormData();
      formData.append('image', compressedFile);

      const uploadResponse = await axios.post(`${BASE_URL}/aws/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const imageUrl = uploadResponse.data.imageUrl;
      const response = await axios.post(`${BASE_URL}/gamer_quest/create`, {
        quest: questId,
        gamer: gamerId,
        participationLink: imageUrl,
        questStatus: "In Review",
        score: 0,
        deliveryDate: new Date().toISOString(),
        comments: [],
      })
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadImageAndUpdateQuest = createAsyncThunk(
  'quests/uploadImageAndUpdateQuest',
  async ({ file, questId, previousParticipationLink }, { rejectWithValue }) => {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      });

      const formData = new FormData();
      formData.append('image', compressedFile);

      const uploadResponse = await axios.post(`${BASE_URL}/aws/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const imageUrl = uploadResponse.data.imageUrl;

      const updateResponse = await axios.put(`${BASE_URL}/gamer_quest/update/${questId}`, {
        participationLink: imageUrl,
        questStatus: 'In Review'
      });


      if (previousParticipationLink.includes("https://ashara-web.s3.")) {
        await axios.delete(`${BASE_URL}/aws/delete`, {
          data: { imageUrl: previousParticipationLink }
        });
      }

      return updateResponse.data;
    } catch (error) {
      console.error('Error en uploadImageAndUpdateQuest:', error.response?.data || error.message);
      alert("Intenta de nuevo subir la imagen");
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

const questsSlice = createSlice({
  name: "quests",
  initialState: {
    items: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGamerQuests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGamerQuests.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchGamerQuests.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createGamerQuest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createGamerQuest.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.items.findIndex(
          (item) => item._id === action.payload._id
        );
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(createGamerQuest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(uploadImageAndUpdateQuest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadImageAndUpdateQuest.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.items.findIndex(
          (item) => item._id === action.payload.quest._id
        );
        if (index !== -1) {
          state.items[index] = action.payload.quest;
        }
      })
      .addCase(uploadImageAndUpdateQuest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default questsSlice.reducer;
