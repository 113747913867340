import "./PageNotFound.css";

function PageNotFound({ message }) {
    return (
        <div className="containerPageNotFound">
            <img
                src="/img/logoBig.png"
                alt="background Page Not Found"
                className="backgroundPageNotFound"
            />
            <div className="pageNotFound">
                <img src="/img/animation.gif" alt="animation" />
                <h2>{message}</h2>
            </div>
        </div>
    );
}

export default PageNotFound;