import React from "react";
import "./Home.css";
import ArticlesPreview from "./ArticlesPreview/ArticlesPreview";
import Articles from "../../data/Articles.json";
import RedesSociales from "../../components/RedesSociales/RedesSociales";
import Carousel from "./Carousel/Carousel";
import Partners from "../../components/Partners/Partners";

export default function Home({ ips }) {
  let articleArray = Articles.articles;
  return (
    <>
      <article>
        <Carousel ips={ips} />
        <section className="containertext-ashara">
          <p className="text-ashara">
            Ashara Studios is a video game studio made up by video game fans who
            <br />
            work hard on creating significant experiences for gamers.
            <br />   <br />
            Our motto is to make high quality video games that transcend into transmedia products and<br /> generate meaningful experiences in people.
          </p>
        </section>
        <section className="container-article">
          {articleArray.map((article, index) => (
            <ArticlesPreview
              key={"article" + index}
              articleName={article.articleName}
              coverImage={article.coverImage}
              description={article.description}
              link={article.link}
            />
          ))}
        </section>

        <hr />
        <section className="container-partners">
          <h1 className="headline">Partners <hr /></h1>
          <Partners />
        </section>
        <hr />
        <section className="container-socialNetwork">
          <h1 className="socialNetwork-followUs">Follow Us</h1>
          <RedesSociales />
        </section>
      </article>
    </>
  );
}
