import React, { useState, useEffect } from 'react';
import './Carousel.css';
import { Link } from "react-router-dom";
import Button from '../../../components/Buttton/Button';

const Carousel = ({ ips }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % ips.length);
        }, 10000);

        return () => clearInterval(timer);
    }, [currentIndex, ips.length]);

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="carousel-container">
            <div className="carousel-slide">
                {ips.map((slide, index) => (
                    <div
                        key={index}
                        className={`carousel-content ${index === currentIndex ? 'active' : ''}`}
                        style={{ backgroundImage: `url(${slide.backgroundImage})` }}
                    >
                        <div className="carousel-text">
                            <img src={slide.largeLogo} alt={slide.name} loading="lazy" />
                            <p dangerouslySetInnerHTML={{ __html: slide.description }}></p>
                            <Button>
                                <Link to={`/Games/${encodeURIComponent(slide.name)}`}>Explore</Link>
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="carousel-indicators">
                {ips.map((_, index) => (
                    <span
                        key={index}
                        className={`indicator ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => goToSlide(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
