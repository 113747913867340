import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import "./Ip.css";
import Game from "./Game/Game";
import ListGames from "./ListGames/ListGames"

const Ip = ({ ips }) => {
  const { ipName } = useParams();
  const selectedIp = ips.find((ip) => ip.name === decodeURIComponent(ipName));
  const games = selectedIp ? selectedIp.games : [];
  const [selectedGameIndex, setSelectedGameIndex] = useState(0);

  const handleGameClick = useCallback((index) => {
    setSelectedGameIndex(index);
  }, []);

  const startGameRotation = useCallback(() => {
    if (games.length === 0) return;

    const interval = setInterval(() => {
      setSelectedGameIndex((prevIndex) => (prevIndex + 1) % games.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [games.length]);

  useEffect(() => {
    return startGameRotation();
  }, [startGameRotation]);

  if (!selectedIp) {
    return <div>IP not found</div>;
  }


  return (
    <article
      className="container-ip"
      style={{
        "--background-color": selectedIp.colors?.[1]?.color || "#000",
        "--drop-shadow": selectedIp.colors?.[5]?.color || "#000"
      }}
    >
      <section
        className="container-ip__details"
        style={{ backgroundImage: `url(${selectedIp.secondaryBackgroundImage})` }}
      >
        <img src={selectedIp.logo} alt={selectedIp.name} loading="lazy" />
        <p dangerouslySetInnerHTML={{ __html: selectedIp.description }}></p>
      </section>

      <section className="continer-games">
        <Game game={games[selectedGameIndex]} background={selectedIp.filterColor2} />
        <nav className="continer-games__menu">
          {games.map((game, index) => (
            <div className={`${index === selectedGameIndex ? "active" : ""}`}
              onClick={() => handleGameClick(index)}
            ><ListGames
              game={game}
            >
                {game.name}
              </ListGames>


            </div>

          ))}
        </nav>
      </section>
    </article>
  );
};

export default Ip;