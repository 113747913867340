import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { checkSessionExpiration } from './redux/authSlice';

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./views/Home/Home";
import AsharaSeason from "./views/AsharaSeason/AsharaSeason";
import Login from "./components/LogIn/LogIn";
import SignUp from "./components/SignUp/SignUp";
import Dashboard from "./views/Dashboard/Dashboard"
import Profile from "./views/Profile/Profile";
import NewPassword from "./components/NewPassword/NewPassword";
import PageNotFound from "./views/PageNotFound/PageNotFound";
import { fetchItem } from "./redux/itemSlice";
import { fetchIPs } from './redux/IpsSlice';
import { fetchSeason } from './redux/seasonSlice'
import { selectSeasonStatus } from './redux/seasonSlice';

import Loading from "./components/Loading/Loading";
import Ip from "./views/IP/Ip";

import { ASHARA_SEASON } from "./variables";

function App() {
  const { ips, statusIps } = useSelector((state) => state.ips);
  const statusSeason = useSelector(selectSeasonStatus);
  const statusItems = useSelector(state => state.items.statusItems);

  const dispatch = useDispatch();

  useEffect(() => {
    checkSessionExpiration(dispatch);
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchIPs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSeason());
  }, [dispatch]);

  useEffect(() => {
    if (statusItems === 'idle') {
      dispatch(fetchItem());
    }
  }, [statusItems, dispatch]);

  if (statusIps === 'loading' && statusSeason === 'loading' && statusItems === 'loading') return <Loading />;
  if (statusIps === 'failed' && statusSeason === 'failed' && statusItems === 'failed') return <PageNotFound message="The Web is currently unavailable. Please try again later." />;

  return (
    <>
      <Header ips={ips} />
      <a
        href="https://discord.gg/cw47QzmZed"
        className="btn-flotante"
        target={"_blank"}
        rel="noreferrer"
      >
        <img
          className="btn-img"
          src="/img/discord-mark-white.png"
          alt="logo dicord"
        />
      </a>
      <Routes>
        <Route path="/" element={<Home ips={ips} />} />
        <Route path={`/${ASHARA_SEASON}`} element={<AsharaSeason />} />
        <Route path={`/${ASHARA_SEASON}/leaderboard`} element={<AsharaSeason />} />
        <Route path="/Quests" element={<AsharaSeason />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/SignIn" element={<Login />} />
        <Route path="/ResetPassword" element={<NewPassword />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/Dashboard/Inventory" element={<Dashboard />} />
        <Route path="/Dashboard/Medals" element={<Dashboard />} />
        <Route path="/Dashboard/SeasonPass" element={<Dashboard />} />
        <Route path="/Games/:ipName" element={<Ip ips={ips} />} />
        <Route
          path="*"
          element={<PageNotFound message="The page you are looking for does not exist." />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
