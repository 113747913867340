import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMedals } from '../../../redux/medalsSlice';
import "./Medals.css";
import Loading from "../../../components/Loading/Loading";

export default function Medals() {
    const dispatch = useDispatch();
    const { statusMedals, errorMedals, medals } = useSelector((state) => state.medals);
    const { user } = useSelector((state) => state.auth);


    useEffect(() => {
        if (user !== null & statusMedals === "idle") {
            dispatch(fetchMedals(user._id));
        }
    }, [user, statusMedals, dispatch]);


    const countUserHasMedals = (medals) => {
        return medals.reduce((count, medal) => {
            if (medal.userHasMedal) {
                return count + 1;
            }
            return count;
        }, 0);
    };

    if (statusMedals === "loading") {
        return <Loading />
    }

    if (statusMedals === "failed") {
        return <div>Error: {errorMedals}</div>;
    }
    return (
        <div className="container-nft2">
            <p className="txt-nfts">{countUserHasMedals(medals)} out of {medals.length} Medals</p>
            <section className="container-medals">
                {medals.map((medal) => (
                    <div key={medal.contract + medal.identifier} className={`container-medals1 ${!medal.userHasMedal ? "Disabled" : ''}`}>
                        <div className="container-inv-medal">
                            <img className="img-medal" src={medal.display_image_url} alt={medal.name} />
                            <div>
                                <p className="txt-medal">{medal.name}</p>
                                <hr />
                                <p className="txt-medal-desc">{medal.description}</p>
                            </div>
                        </div>
                    </div>
                ))
                }
            </section>
        </div>
    );
}