import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImageAndUpdateQuest, createGamerQuest } from '../../redux/questsSlice';
import "./ImageUploader.css";
import Button from '../Buttton/Button';

const ImageUploader = ({ state, questId, previousParticipationLink }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileError, setFileError] = useState(null);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const { status, error } = useSelector((state) => state.quests);
    const { user } = useSelector((state) => state.auth);

    const btnVariant = state === 'completed' ? 'success' : state === 'not-approved' ? 'danger' : 'primary';

    const handleFileChange = (e) => {
        const file = e.target.files?.[0];

        if (file) {
            if (file.size > 10 * 1024 * 1024) {
                setFileError('The file cannot be larger than 10 MB.');
                setSelectedFile(null);
            } else {
                setSelectedFile(file);
                setFileError(null);
            }
        }
    };

    const handlePaste = (e) => {
        const items = e.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                if (blob.size > 10 * 1024 * 1024) {
                    setFileError('The file cannot be larger than 10 MB.');
                    setSelectedFile(null);
                } else {
                    setSelectedFile(blob);
                    setFileError(null);
                }
                break;
            }
        }
    };

    const handleSubmit = async () => {
        if (selectedFile) {
            try {
                await dispatch(uploadImageAndUpdateQuest({
                    file: selectedFile,
                    questId: questId,
                    previousParticipationLink: previousParticipationLink
                })).unwrap();
                setSelectedFile(null);
            } catch (err) {
                console.error('Mission update failed:', err);
            }
        } else {
            alert('Please select an image.');
        }
    };

    const handleCreateGamerQuest = async () => {
        if (user) {
            try {
                await dispatch(createGamerQuest({
                    file: selectedFile,
                    questId: questId,
                    gamerId: user._id
                })).unwrap();
                setSelectedFile(null);
            } catch (err) {
                console.error('Mission creation failed:', err);
            }
        }
    };

    return (
        <div
            className="upload-container"
            onPaste={handlePaste}
        >
            <div
                className={`drop-area ${state}`}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                    e.preventDefault();
                    const file = e.dataTransfer.files?.[0];

                    if (file) {
                        if (file.size > 10 * 1024 * 1024) {
                            setFileError('The file cannot be larger than 10 MB.');
                            setSelectedFile(null);
                        } else {
                            setSelectedFile(file);
                            setFileError(null);
                        }
                    }
                }}
            >
                {selectedFile ? (
                    <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Preview"
                        className="image-preview"
                    />
                ) : (
                    <div className="upload-instructions">
                        <p>{state === "completed" ?
                            "To improve your score, drag and drop an image here or click to select one." :
                            "Drag and drop an image here or click to select image."}
                        </p>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept="image/*"
                            style={{ display: 'none' }}
                        />
                    </div>
                )}
            </div>

            {selectedFile ? (
                <>
                    <Button onClick={() => setSelectedFile(null)} size='btn-xsm' variant='secondary'>
                        Delete image
                    </Button>
                    <Button
                        onClick={state === "todo" ? handleCreateGamerQuest : handleSubmit}
                        disabled={status === "loading"}
                        variant={btnVariant}
                        size='btn-xsm'
                    >
                        {status === "loading" ? 'Sending...' : 'Submit'}
                    </Button>
                </>
            ) : (
                <Button
                    onClick={() => fileInputRef.current.click()}
                    size='btn-xsm'
                    variant={btnVariant}
                >
                    Select image
                </Button>
            )}

            {fileError && <p className="txt-error">{fileError}</p>}
            {error && <p className="txt-error">{error}</p>}
        </div>
    );
};

export default ImageUploader;