import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../variables';

export const createPreregister = createAsyncThunk(
  'preregister/createPreregister',
  async ({ userId, seasonId }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/preregister`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, seasonId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const checkPreregister = createAsyncThunk(
  'preregister/checkPreregister',
  async ({ userId, seasonId }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${BASE_URL}/preregister/check?userId=${userId}&seasonId=${seasonId}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const preregisterSlice = createSlice({
  name: 'preregister',
  initialState: {
    isPreregistered: false,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Manejo de createPreregister
      .addCase(createPreregister.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPreregister.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isPreregistered = action.payload.isPreregistered;
      })
      .addCase(createPreregister.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Manejo de checkPreregister
      .addCase(checkPreregister.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkPreregister.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isPreregistered = action.payload.isPreregistered;
      })
      .addCase(checkPreregister.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const selectIsPreregistered = (state) => state.preregister.isPreregistered;
export const selectPreregisterStatus = (state) => state.preregister.status;
export const selectPreregisterError = (state) => state.preregister.error;

export default preregisterSlice.reducer;