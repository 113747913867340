import React, { useEffect, useState } from "react";
import "./Profile.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { updateUserProfile } from '../../redux/authSlice';
import Loading from "../../components/Loading/Loading";

export default function Profile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error, user } = useSelector((state) => state.auth);
    const [email, setEmail] = useState('');
    const [formData, setFormData] = useState({
        wallet: '',
        discordNickname: '',
        twitterNickname: '',
        profileTSB: ''
    });

    useEffect(() => {
        if (!user) {
            navigate('/SignIn');
        } else {
            setFormData({
                wallet: user.wallet || '',
                discordNickname: user.nickname || '',
                twitterNickname: user.socialNetwork[0].profile || '',
                profileTSB: user.profileTSB || ''
            });
            setEmail(user.email);
        }
    }, [user, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const userUpdated = {
            ...formData,
            socialNetwork: { socialNetwork: "Twitter", profile: formData.twitterNickname }
        }
        delete userUpdated.twitterNickname
        dispatch(updateUserProfile({ id: user._id, data: userUpdated }));
    };

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <section className="container-profile">
            <h1 class="headline">
                Profile
                <hr />
            </h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        disabled
                    />
                </div>
                <div>
                    <label>TSB Nickname:</label>
                    <input
                        type="text"
                        name="profileTSB"
                        value={formData.profileTSB}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label> Wallet:</label>
                    <input
                        type="text"
                        name="wallet"
                        value={formData.wallet}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label>Discord Nickname:</label>
                    <input
                        type="text"
                        name="discordNickname"
                        value={formData.discordNickname}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label>Twitter Nickname:</label>
                    <input
                        type="text"
                        name="twitterNickname"
                        value={formData.twitterNickname}
                        onChange={handleChange}
                    />
                </div>
                <div></div>
                <div className="continer-button">
                    <button className="btn-form" type="submit">Edit</button>
                </div>
            </form>
            <img
                className="bg-profile"
                src="img/User/bg-profile.png"
                alt="fondo de botones user"
            />
        </section >

    );
};


