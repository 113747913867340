import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const BASE_URL = 'https://walrus-app-8wc7g.ondigitalocean.app/ip';

export const fetchIPs = createAsyncThunk('ip/fetchIPs', async () => {
    const response = await fetch(BASE_URL);
    if (!response.ok) {
        throw new Error('Failed to fetch ips');
    }
    const data = await response.json();
    return data;
});

const ipsSlice = createSlice({
    name: 'ips',
    initialState: {
        ips: [],
        statusIps: 'idle',
        errorIps: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchIPs.pending, (state) => {
                state.statusIps = 'loading';
            })
            .addCase(fetchIPs.fulfilled, (state, action) => {
                state.statusIps = 'succeeded';
                state.ips = action.payload;
            })
            .addCase(fetchIPs.rejected, (state, action) => {
                state.statusIps = 'failed';
                state.statusIps = action.error.message;
            });
    },
});

export default ipsSlice.reducer;
