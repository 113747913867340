import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../variables';

export const buyPass = createAsyncThunk(
  'pass/buyPass',
  async ({ gamerId, contract, idContract}, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/pass/buy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ gamerId, contract, idContract }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const passSlice = createSlice({
  name: 'pass',
  initialState: {
    transactionPass: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(buyPass.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(buyPass.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.transactionPass = action.payload;
      })
      .addCase(buyPass.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
  },
});

export const selectTransactionPass = (state) => state.pass.transactionPass;
export const selectTransactionPassStatus = (state) => state.pass.status;
export const selectTransactionPassError = (state) => state.pass.error;

export default passSlice.reducer;