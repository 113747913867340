
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signup } from "../../redux/authSlice";
import "./SignUp.css";

const SignUp = () => {
  const [nickname, setNickname] = useState("");
  const [wallet, setWallet] = useState("");
  const [profileTSB, setProfileTSB] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [socialNetwork, setSocialNetwork] = useState([]);
  const [isValid, setIsValid] = useState(true);

  const dispatch = useDispatch();
  const { loading, error, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      nickname,
      wallet,
      profileTSB,
      email,
      password,
      socialNetwork,
    };
    dispatch(signup(userData));
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setWallet(value);
    setIsValid(validateWallet(value));
  };

  const validateWallet = (walletAddress) => {
    const ethAddressPattern = /^0x[a-fA-F0-9]{40}$/;
    return ethAddressPattern.test(walletAddress);
  };

  useEffect(() => {
    if (user) {
      navigate("/Inventory");
    }
  }, [user, navigate]);

  return (
    <div className="container-SignUp">
      <img alt="logo Ashara" src="/img/User/logo-ashara.png" />
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <div className="container-inputs">
          <div>
            <label>Email:</label>
            <input
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label>Nickname The Sandbox:</label>
            <input
              type="text"
              autoComplete="nickname"
              required
              value={profileTSB}
              onChange={(e) => setProfileTSB(e.target.value)}
            />
          </div>
          <div>
            <label>Wallet:</label>
            <input
              type="text"
              value={wallet}
              required
              pattern="^0x[a-fA-F0-9]{40}$"
              title="Invalid blockchain wallet address"
              onChange={handleChange}
            />
            {!isValid && (
              <p className="txt-form">Invalid blockchain wallet address</p>
            )}
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              required
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <label>Nickname Discord:</label>
            <input
              type="text"
              required
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
          </div>
          <div>
            <label>Twitter</label>
            <input
              type="text"
              value={setSocialNetwork.profile}
              onChange={(e) =>
                setSocialNetwork([
                  { socialNetwork: "Twitter", profile: e.target.value },
                ])
              }
            />
          </div>
        </div>
        <button
          className="btn-form"
          type="submit"
          disabled={loading || isValid === false}
        >
          {loading ? "Signing up..." : "Sign Up"}
        </button>
        {error && <p className="txt-form">{error.msg}</p>}
      </form>
    </div>
  );
};

export default SignUp;
