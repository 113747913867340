import React from "react";
import "./Game.css";

const Game = ({ game }) => {
  const formatStores = (stores) => {
    if (!stores || stores.length === 0) {
      return "No platforms available";
    }
    const activeStores = stores.filter((store) => store.active);
    const storeNames = activeStores.map((store) => store.nameStore);
    if (storeNames.length === 0) {
      return "No active platforms available";
    }
    if (storeNames.length === 1) {
      return storeNames[0];
    }
    const lastStore = storeNames.pop();
    return `${storeNames.join(", ")} y ${lastStore}`;
  };

  const borderGradient = game.borderGradient || "linear-gradient(180deg, rgba(255,0,0,1) 0%, rgba(153,0,0,0) 100%)";

  return (
    <article
      className="container-game"
      style={{
        backgroundImage: `url(${game.background})`,
        "--border-gradient": borderGradient,
      }}
    >
      <img src={game.largeLogo} alt={game.name} loading="lazy" />
      <div className="container-game__details">
        <p className="container-game__tittle">{game.name}</p>
        <p className="container-game__subtittle">{game.gameType}</p>
        <p className="container-game__desc">{game.description}</p>
        <p className="container-game__platform">Platform: {formatStores(game.stores)}</p>
        <div className="container-game__link">
          {game.stores.map((store, index) => (
            store.active === true &&
            <a href={store.link}
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={store.imageStore} alt={store.nameStore} loading="lazy" index={index} />
            </a>
          ))}
        </div>

      </div>
    </article>

  );
};

export default Game;