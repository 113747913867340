import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import "./AsharaSeason.css";
import BarProgress from "../../components/BarProgress/BarProgress";
import RedesSociales from "../../components/RedesSociales/RedesSociales";
import { selectSeason, selectSeasonStatus } from '../../redux/seasonSlice';
import { ASHARA_SEASON_STYLE, NUMBER_SANDS } from "../../variables";
import Preregister from "./Preregister/Preregister";
import { buyPass, selectTransactionPassStatus } from '../../redux/passSlice';
import { fetchInventory, subtractAsharaCoins } from "../../redux/inventorySlice";
import PopUp from '../../components/PopUp/PopUp';
import Button from "../../components/Buttton/Button";
import Leaderboard from "./Leaderboard/Leaderboard";
import Quests from "../Quests/Quests";

export default function AsharaSeason() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const season = useSelector(selectSeason);
  const statusSeason = useSelector(selectSeasonStatus);
  const { user } = useSelector((state) => state.auth);
  const { statusInventory, inventory } = useSelector((state) => state.inventory);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionLink, setTransactionLink] = useState(null);
  const [coinItem, setCoinItem] = useState({ quantity: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (user !== null && statusInventory === "idle") {
      dispatch(fetchInventory(user._id));
    }
  }, [user, statusInventory, dispatch]);

  useEffect(() => {
    if (inventory !== null) {
      const coin = inventory.find((inv) => inv.item.type === "Coin");
      setCoinItem(coin || { quantity: 0 });
    }
  }, [inventory]);

  const handleBuyPass = async () => {
    try {
      setIsModalOpen(true);
      const result = await dispatch(
        buyPass({
          gamerId: user._id,
          contract: season.asharaPassContract,
          idContract: season.idContract,
        })
      ).unwrap();

      if (result) {
        dispatch(subtractAsharaCoins(25000));
      }

      setTransactionLink(result.transactionLink);
    } catch (err) {
      alert("Error buying pass:", err);
      setIsModalOpen(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTransactionLink(null);
  };

  return (
    statusSeason === 'succeeded' && (
      <article className="container-season">
        <section className="season-menu">
          <Button
            variant='secondary'
            size="btn-sm"
            onClick={() => navigate("/AsharaSeasonIII")}
            isActive={location.pathname === "/AsharaSeasonIII"}
          >
            Season
          </Button>
          <Button
            variant='secondary'
            size="btn-sm"
            onClick={() => navigate("/AsharaSeasonIII/leaderboard")}
            isActive={location.pathname.includes("leaderboard")}
          >
            Leaderboard
          </Button>
          <Button
            variant='secondary'
            size="btn-sm"
            onClick={() => navigate("/Quests")}
            isActive={location.pathname.includes("Quests")}
          >
            Quests
          </Button>
        </section>

        {location.pathname.includes("leaderboard") ? (
          <Leaderboard />
        ) : location.pathname.includes("Quests") ? (
          <Quests />
        ) : (
          <div className="container-season-info">

            <section>
              <h1 className="headline">Ashara Season<hr /></h1>
              <p className="text-season" dangerouslySetInnerHTML={{ __html: season.description }}>
              </p>
            </section>
            <Preregister seasonId={season._id} />
            <hr />
            <section className="container-sandPool">
              <h1 className="headline">Sand reward pool<hr /></h1>
              <div className="container-barProgress">
                <p className="info-week">
                  Week
                </p>
                <div className="container-barProgress2">
                  <img
                    className="initial-bar"
                    src="/img/AsharaSeason/Bar/line0.png"
                    alt="Inital bar"
                  />
                  {NUMBER_SANDS.map((milestone, index) => (
                    <BarProgress
                      key={"Hito" + index}
                      sand={milestone.sand}
                      milestone={milestone.players}
                    />
                  ))}
                </div>

                <div className="container-reward">
                  <img
                    className="backgorund-reward"
                    src="/img/AsharaSeason/Bar/coin2BG.png"
                    alt="SAND 2 BG"
                  />
                  <img
                    className="coin-reward"
                    src="/img/AsharaSeason/Bar/coin2.png"
                    alt="SAND 2"
                  />
                </div>
              </div>
              <hr />

              <div className="container-infoReward">
                <img
                  className="backgorund-infoReward"
                  src="/img/AsharaSeason/FrameInfo.png"
                  alt="backgorund"
                />
                <p>
                  **<span>10%</span> of the profits generated by Ashara in the builder challenge will be distributed as follows: <span>8%</span> to the players and <span>2%</span> to the creators.
                </p>
                <img
                  className="chest-infoReward"
                  src="/img/AsharaSeason/chest.png"
                  alt="backgorund"
                />
              </div>
              <hr />
            </section>
            <div className="container-info-benefits">
              <img
                className="backgorund-info-benefits"
                src={ASHARA_SEASON_STYLE.background}
                alt="backgorund"
              />
            </div>
            <div className="container-info">
              <section className="container-infoSeason">
                <div className="container-benefits">
                  <h2 className="subtitle-season">BENEFITS</h2>
                  <ul>
                    {season.reward.BENEFITS.map((benefit, index) => (
                      <>
                        <li>{benefit}</li>
                        <hr />
                      </>
                    ))}
                  </ul>
                </div>
                <div className="element-free">
                  <div className="content-free">
                    <h2 className="subtitle-season">FREE TO PLAY</h2>
                    <ul>
                      {season.reward.FREETOPLAY.map((free, index) => (
                        <li key={"free" + index}>
                          {free}
                          <img
                            className="shadow"
                            src="/img/AsharaSeason/blue.png"
                            alt="blue"
                          />
                          <hr />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="element-pass">
                  <div className="content-pass">
                    <div className="subtitle-season">
                      <img
                        className="subtitle-start"
                        src="/img/AsharaSeason/start.png"
                        alt="purple"
                      />
                      <img
                        className="subtitle-start"
                        src="/img/AsharaSeason/start2.png"
                        alt="purple"
                      />
                      <h2> ASHARA PASS</h2>
                      <img
                        className="subtitle-start"
                        src="/img/AsharaSeason/start2.png"
                        alt="purple"
                      />
                      <img
                        className="subtitle-start"
                        src="/img/AsharaSeason/start.png"
                        alt="purple"
                      />
                    </div>
                    <ul>
                      {season.reward.ASHARAPASS.map((pass, index) => (
                        <li key={"pass" + index}>
                          {pass}
                          <img
                            className="shadow"
                            src="/img/AsharaSeason/purple.png"
                            alt="purple"
                          />
                          <hr />
                        </li>
                      ))}
                    </ul>
                  </div>

                </div>
              </section>
              <div className={`container-buy ${!user ? 'max-width' : ''}`}>
                {
                  user && (
                    coinItem.quantity >= 25000 ?
                      <button
                        className="button2"
                        onClick={handleBuyPass}
                        disabled={selectTransactionPassStatus === 'loading'}
                      >
                        Buy With Ashara Coins
                      </button>
                      :
                      <>
                        <button
                          className="button2-desactive"
                          onMouseEnter={() => setShowTooltip(true)}
                          onMouseLeave={() => setShowTooltip(false)}
                        >
                          Insufficient Coins
                        </button>
                        {showTooltip && (
                          <div className="tooltip">
                            You need {25000 - coinItem?.quantity} more Ashara Coins.
                          </div>)}
                      </>
                  )
                }
                <a
                  href={`https://opensea.io/assets/matic/${season.asharaPassContract}/${season.idContract}`}
                  target="_blank"
                  rel="noreferrer"
                  className={`button2`}
                >
                  Buy In Opensea!
                </a>
              </div>
              <img
                className="float-turtle"
                src={ASHARA_SEASON_STYLE.image}
                alt="Pass"
              />
            </div>
            <section className="container-socialNetwork">
              <h1 className="socialNetwork-followUs">Follow Us</h1>
              <RedesSociales />
            </section>
            {isModalOpen && (
              <PopUp onClose={() => closeModal()} preregistered="true" noClose={transactionLink ? false : true}>
                <div className="container-buyPopUp">
                  <h2>Buy Pass</h2>
                  <hr />
                  {transactionLink ? (
                    <>
                      <p className="txt-popUp2">
                        The transaction was completed successfully,<br /> and the pass was delivered to your wallet.
                      </p>
                      <p className="txt-subtitle2">Transaction link:</p>
                      <a
                        className="txt-link"
                        href={transactionLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {transactionLink}
                      </a>
                    </>
                  ) : (
                    <p className="txt-popUp2">Processing your purchase...</p>
                  )}
                </div>
              </PopUp>
            )}
          </div>
        )}
      </article>
    )
  );
}