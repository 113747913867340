import React from "react";
import "./Footer.css";
export default function Footer() {
  return (
    <footer>
      <img
        className="logo"
        src="/img/2.Footer/logo.svg"
        alt="footer-logo"
      />
      <div className="filler"></div>
      <div className="emailme">
        <p className="text1">
          <a href="mailto:info@asharastudios.com">INFO@ASHARASTUDIOS.COM</a>
        </p>

        <img
          className="favicon"
          src="/img/2.Footer/letter.svg"
          alt="favicon letter"
        />
      </div>
      <div className="callme">
        <p className="text1">
          <a href="tel:+573142114475">(+57) 314 2114475</a>
        </p>
        <img
          className="favicon"
          src="/img/2.Footer/telephone.svg"
          alt="favicon telephone"
        />
      </div>
      <div className="closing">
        <div className="privacy">
          <img
            className="favicon policy"
            src="/img/2.Footer/policy.svg"
            alt="favicon telephone"
          />
          <a
            href="https://drive.google.com/uc?id=1plFomls8sJb4DJp_1pABriuN-wp_t92A"
            className="text2"
             aria-label="Leer nuestras políticas de privacidad"
          >
            Privacy Policies
          </a>
        </div>
        <p className="text2">
          Copyright © Ashara Studios {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
}
